import React from 'react';
import styled from 'styled-components';
import { Button } from 'components/button';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import MEDIA from 'styles/media';

const STextMonthlyPromo = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 100px;

  ${MEDIA.max1024`
    padding: 0 20px;
  `};
`;

const SHeader = styled.h2`
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const SSubHeader = styled.p`
  font-size: 18px;
  margin-bottom: 10px;
`;

const SText = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
`;

const SButton = styled(Button)`
  margin-top: 1rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

const SList = styled.ul`
  margin: 0;
  list-style-type: circle;
  text-align: left;
  list-style-position: outside;
`;
const SListItem = styled.li``;

export const TextMonthlyPromo = () => {
  return (
    <STextMonthlyPromo>
      <SHeader>
        WIOSENNE PROMOCJE
      </SHeader>
      <SSubHeader>
        Wiosna to czas, kiedy wszystko rozkwita, dlatego chcemy, aby Twoja uroda również promieniała słonecznym blaskiem. Z tej okazji przygotowaliśmy dwie specjalne WIOSENNE PROMOCJE, z których możesz skorzystać przez cały kwiecień.
      </SSubHeader>
      <SText>
        Zabiegi z <a href="/oferta/polinukleotydy-pluryal/" target="_blank">wykorzystaniem polinukleotydów:</a>
      </SText>
      <SList>
        <SListItem>za zabieg na twarz zapłacisz 900 zł zamiast 1050 zł</SListItem>
        <SListItem>za zabieg na okolice oczu zapłacisz 800 zł zamiast 950 zł</SListItem>
      </SList>
      <SText>
        Zabiegi <a href="/oferta/usuwanie-zmarszczek-mimicznych-bot/" target="_blank">redukcji zmarszczek z toksyną</a>r:
      </SText>
      <SList>
        <SListItem> zapłacisz 450 zł zamiast 500 zł</SListItem>
        <SListItem> zapłacisz 800 zł zamiast 900 zł</SListItem>
        <SListItem> zapłacisz 1050 zł zamiast 1200 zł</SListItem>
      </SList>
      <SButton
        text="Zarezerwuj wizytę"
        target="_blank"
        href={EXTERNAL_LINKS.BOOKSY_BOOKING_APP}
        on
      />
      <SText>
        Wybieraj bezpieczne i sprawdzone rozwiązania. W naszej klinice pracujemy tylko na legalnych medycznych produktach.
      </SText>
      <SText>
        Jeśli w kwietniu zdecydujesz się na wykonanie u nas zabiegu z wykorzystaniem polinukleotydów lub toksyny botulinowej <strong>OTRZYMASZ PROMOCYJNĄ CENĘ! Wizyta może odbyć się w kwietniu lub maju.</strong>
      </SText>
      <SText>
        ZAPISZ SIĘ na wizytę kwalifikującą do zabiegu już dziś. Zapraszamy!
      </SText>
    </STextMonthlyPromo>
  );
};
