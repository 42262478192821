import React from 'react';
import { NonStretchedImage } from './banner-promo-image';
import { graphql, useStaticQuery } from 'gatsby';

export const ImageMonthlyPromo = () => {
  const image = useStaticQuery(graphql`
    query {
      promo: file(relativePath: { eq: "promo-banner.png" }) {
        childImageSharp {
          gatsbyImageData(width: 450, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <NonStretchedImage
      childImageSharp={image.promo.childImageSharp}
      alt="promocja ideale medycyna estetyczna"
    />
  );
};
