import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { object, string } from 'prop-types';

const SImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

export const NonStretchedImage = ({ childImageSharp, alt, className }) => {
  return (
    <SImageWrapper className={className}>
      <GatsbyImage
        className={className}
        image={childImageSharp.gatsbyImageData}
        alt={alt}
        style={{ width: '100%', height: '100%' }} // fill the container
        imgStyle={{ objectFit: 'cover', objectPosition: 'center' }} // cover area, center image
      />
    </SImageWrapper>
  );
};

NonStretchedImage.defaultProps = {
  className: '',
  alt: '',
};

NonStretchedImage.propTypes = {
  childImageSharp: object.isRequired,
  className: string,
  alt: string,
};
